angular.module('comcast.common.communication').factory('SpecialOrderTypeResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.specialOrderTypeEndPoint, {
    id: '@id'
  }, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.specialOrderTypeEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('special order types').setAction('Retrieving')
    }
  });
}]);