angular.module('comcast.common.communication').factory('OrderHoldReasonResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.orderHoldReasonEndPoint + '/:id', {
    id: '@id'
  }, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.orderHoldReasonEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('order hold reasons').setAction('Retrieving')
    }
  });
}]);