import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';

import { EndPointService } from "../endpoint-service";

declare var angular: angular.IAngularStatic;

interface Params {
    accountType?: string
}

@Injectable()
export class WelcomeMessageResource {
    private apiRoot:string;

    constructor(
        private http: HttpClient,
        private endpointService: EndPointService
    ) {
        this.apiRoot = this.endpointService.welcomeMessageEndPoint;
    }

    get(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.accountType}`)
    }

    save(params: Params, body:any):Observable<any> {
        return this.http.post(`${this.apiRoot}/${params.accountType}`, body)
    }
}

angular.module('comcast.common.communication').factory('WelcomeMessageResourceFactory', downgradeInjectable(WelcomeMessageResource));
