angular.module('comcast.common.communication').factory('GuideResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.guideEndPoint + '/:id', {
    id: '@id'
  }, {
    get: {
      method: 'GET',
      url: ResourceEndPointService.guideEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('guides').setAction('Retrieving')
    },
    getOne: {
      method: 'GET',
      url: ResourceEndPointService.guideEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('guides').setAction('Retrieving')
    },
    getGuideFile: {
      method: 'GET',
      url: ResourceEndPointService.guideEndPoint + '/:id/file',
      params: {
        id: '@id'
      },
      isArray: false,
      headers: {
        Accept: '*/*'
      },
      interceptor: new NotificationInterceptor().setObject('guide').setAction('retrieving'),
      transformResponse: function transformResponse(data) {
        var asset; // Node/Sequelize return a standard non-typed buffer
        // the below code converts that to an arraybuffer so we can blob it

        data = JSON.parse(data);
        var ab = new ArrayBuffer(data.fileData.data.length);
        var view = new Uint8Array(ab);

        for (var i = 0; i < data.fileData.data.length; ++i) {
          view[i] = data.fileData.data[i];
        } // Once we have an arraybuffer, we can convert that to a typed blob


        if (ab) {
          asset = new Blob([ab], {
            type: 'application/pdf'
          });
        }

        return {
          data: asset
        };
      }
    }
  });
}]);