/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
angular.module('comcast.common.communication').factory('AssetResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.assetStreamEndpoint + '/:uuid', {
    uuid: '@uuid'
  }, {
    // Retrieve an asset
    getVideoAsset: {
      method: 'GET',
      url: ResourceEndPointService.assetStreamEndpoint + '/:uuid',
      params: {
        uuid: '@uuid'
      },
      headers: {
        Accept: '*/*'
      },
      responseType: 'arraybuffer',
      interceptor: new NotificationInterceptor().setObject('asset').setAction('Retrieving'),
      transformResponse: function transformResponse(data) {
        var asset;

        if (data) {
          asset = new Blob([data], {
            type: 'video/mp4'
          });
        }

        return {
          data: asset
        };
      }
    },
    getAudioAsset: {
      method: 'GET',
      url: ResourceEndPointService.assetStreamEndpoint + '/:uuid',
      params: {
        uuid: '@uuid'
      },
      headers: {
        Accept: '*/*'
      },
      responseType: 'arraybuffer',
      interceptor: new NotificationInterceptor().setObject('asset').setAction('Retrieving'),
      transformResponse: function transformResponse(data) {
        var asset;

        if (data) {
          asset = new Blob([data], {
            type: 'audio/mp3'
          });
        }

        return {
          data: asset
        };
      }
    },
    getImageAsset: {
      method: 'GET',
      url: ResourceEndPointService.assetStreamEndpoint + '/image/:uuid',
      params: {
        uuid: '@uuid'
      },
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('asset').setAction('Retrieving'),
      responseType: 'arraybuffer',
      ignoreLoadingBar: true
    },
    downloadAsset: {
      method: 'GET',
      url: ResourceEndPointService.assetDownloadEndpoint + '/:uuid',
      params: {
        uuid: '@uuid'
      },
      headers: {
        Accept: '*/*'
      },
      responseType: 'arraybuffer',
      interceptor: new NotificationInterceptor().setObject('asset').setAction('Downloading'),
      transformResponse: function transformResponse(data, headersGetter) {
        var asset;

        if (data) {
          asset = new Blob([data], {
            type: headersGetter()['content-type']
          });
        }

        return {
          data: asset
        };
      },
      ignoreLoadingBar: true
    },
    getQcReportData: {
      method: 'GET',
      url: ResourceEndPointService.assetQcReportEndpoint + '/:assetContentId',
      params: {
        assetContentId: '@assetContentId'
      },
      headers: {
        Accept: 'application/json'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('qc report').setAction('Retrieving')
    },
    getSpotAssetUuids: {
      method: 'GET',
      url: ResourceEndPointService.spotAssetMapEndPoint,
      // This endpoint requires an `id` parameter
      params: {
        id: '@id'
      },
      headers: {
        Accept: 'application/json'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('asset map').setAction('Retrieving')
    },
    getDraftTrafficItem: {
      method: 'GET',
      url: ResourceEndPointService.draftTrafficEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('delivery confirmation report').setAction('Retrieving')
    },
    retrieveOffsiteMedia: {
      method: 'GET',
      url: ResourceEndPointService.retrieveOffsiteMediaEndPoint,
      // params: {id: '@spotId'},
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('offsite media').setAction('Retrieving')
    },
    getVastXml: {
      method: 'GET',
      url: ResourceEndPointService.retrieveVastXmlEndpoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('vast xml').setAction('Retrieving')
    }
  });
}]);