/**
 * Created by samhuckaby on 10/21/15.
 */
angular.module('comcast.common.communication').factory('PasswordResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.passwordResetEndPoint, {
    email: '@email'
  }, {
    resetPassword: {
      method: 'POST',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('password').setAction('Resetting')
    },
    // Resource handler to change a user's password
    changePassword: {
      method: 'POST',
      url: ResourceEndPointService.passwordChangeEndPoint,
      params: {
        oldPass: '@oldPass',
        newPass: '@newPass'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('password').setAction('Changing')
    }
  });
}]);