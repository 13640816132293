import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

declare var angular: angular.IAngularStatic;

interface Params {
    id?: number,
    dashboardId?: number
}

@Injectable()
export class DashboardResource {
    private apiRoot: string = '/api/dashboards';

    constructor(private http: HttpClient) { }

    getAllForAccount(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}/all`)
    }

    getDashboardViewers(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}/viewers`, {
            params: new HttpParams({fromObject: params})
        })
    }
}

angular.module('comcast.common.communication').factory('DashboardResourceFactory', downgradeInjectable(DashboardResource));
