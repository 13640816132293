/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
angular.module('comcast.common.communication').factory('DeliveryResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.deliveryEndPoint + '/:id', {
    id: '@id'
  }, {
    get: {
      method: 'GET',
      url: ResourceEndPointService.deliveryEndPoint + '/:id',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('delivery').setAction('Retrieving')
    },
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.deliveryEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Retrieving')
    },
    getAllForProvider: {
      method: 'GET',
      url: ResourceEndPointService.deliveryProviderEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Retrieving')
    },
    create: {
      method: 'POST',
      url: ResourceEndPointService.deliveryEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Creating')
    },
    retransmitSpots: {
      method: 'POST',
      url: ResourceEndPointService.deliveryEndPoint + '/retransmit',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Retransmitting')
    },
    acceptPromos: {
      method: 'POST',
      url: ResourceEndPointService.deliveryEndPoint + '/acceptpromos',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Accepting')
    },
    directHandlePromos: {
      method: 'POST',
      url: ResourceEndPointService.deliveryEndPoint + '/actions',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Handling')
    },
    updateMetadata: {
      method: 'POST',
      url: ResourceEndPointService.deliveryEndPoint + '/:id/updateMetadata',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('deliveries').setAction('Accepting')
    },
    getAssetMap: {
      method: 'GET',
      url: ResourceEndPointService.deliveryAssetMapEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('delivery asset map').setAction('Retrieving')
    },
    getTrafficAsset: {
      method: 'GET',
      url: ResourceEndPointService.deliveryTrafficAssetEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('delivery traffic asset').setAction('Retrieving')
    },
    getCorollaryInfo: {
      method: 'GET',
      url: ResourceEndPointService.deliveryCorollaryInfoEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('delivery corollary info').setAction('Retrieving')
    },
    makeDeliveryOriginal: {
      method: 'GET',
      url: ResourceEndPointService.deliveryOriginalEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('original generation').setAction('Attempting')
    },
    makeDeliveryProxy: {
      method: 'GET',
      url: ResourceEndPointService.deliveryProxyEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('original generation').setAction('Attempting')
    },
    getReferenceLink: {
      method: 'GET',
      url: ResourceEndPointService.referenceLinkEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('reference link').setAction('Retrieving')
    },
    getMetadataOverride: {
      method: 'GET',
      url: ResourceEndPointService.metadataOverrideEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('reference link').setAction('Retrieving')
    },
    sendForCustomization: {
      method: 'POST',
      url: ResourceEndPointService.customizationEndPoint,
      headers: {
        Accept: '*/*'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('for customization').setAction('Submitting')
    }
  });
}]);