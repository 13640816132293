/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
angular.module('comcast.common.communication').factory('AsperaResourceFactory', ['$q', '$resource', 'EndPointService', 'NotificationInterceptor', function ($q, $resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.asperaByAccountIdEndPoint + '/:id', {
    id: '@id'
  }, {
    getAsperaDetails: {
      method: 'GET',
      url: ResourceEndPointService.asperaByAccountIdEndPoint,
      // This endpoint includes the id param variable
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('Aspera Details').setAction('Retrieving'),
      timeout: $q.defer().promise
    }
  });
}]);