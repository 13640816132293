/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
angular.module('comcast.common.communication').factory('OrderResourceFactory', ['$q', '$resource', 'EndPointService', 'NotificationInterceptor', '$http', function ($q, $resource, ResourceEndPointService, NotificationInterceptor, $http) {
  'use strict';

  return $resource(ResourceEndPointService.orderEndPoint + '/:id', {
    id: '@id'
  }, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('orders').setAction('Retrieving')
    },
    getOrderGroup: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/group/:id',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order group').setAction('Retrieving')
    },
    getOrderGroupTraffic: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/group/traffic',
      params: {
        id: '@id'
      },
      isArray: true,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('order group traffic').setAction('Retrieving')
    },
    saveOrderGroup: {
      method: 'POST',
      url: ResourceEndPointService.orderEndPoint + '/:id/group/:groupId',
      params: {
        id: '@id',
        groupId: 'groupId'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order group').setAction('Saving')
    },
    removeOrderGroupItem: {
      method: 'DELETE',
      url: ResourceEndPointService.orderEndPoint + '/:id/group/:groupId',
      params: {
        id: '@id',
        groupId: 'groupId'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order group item').setAction('Removing')
    },
    removeOrderGroups: {
      method: 'DELETE',
      url: ResourceEndPointService.orderEndPoint + '/:id/group/',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order group').setAction('Removing')
    },
    deleteOrder: {
      method: 'DELETE',
      url: '/api/orders/:id',
      params: {
        id: '@id'
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('orders').setAction('Deleting')
    },
    copyOrder: {
      method: 'POST',
      url: ResourceEndPointService.orderEndPoint + '/:id/copy',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('orders').setAction('Copying')
    },
    submit: {
      method: 'POST',
      url: ResourceEndPointService.orderEndPoint + '/:id/submit',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order').setAction('Saving')
    },
    submitMakelist: {
      method: 'POST',
      url: ResourceEndPointService.makelistEndPoint,
      isArray: false,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('make list').setAction('Submitting')
    },
    optimize: {
      method: 'POST',
      url: ResourceEndPointService.orderEndPoint + '/:id/optimize',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order').setAction('Optimizing')
    },
    getReslates: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/:id/reslates',
      params: {
        id: '@id'
      },
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('order reslates').setAction('Retrieving')
    },
    getPriorityCheck: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/:id/priorityCheck',
      params: {
        id: '@id'
      },
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('order priority check').setAction('Retrieving')
    },
    recall: {
      method: 'POST',
      url: ResourceEndPointService.orderRecallEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order').setAction('Recalling')
    },
    checkTeamCompanies: {
      method: 'GET',
      url: ResourceEndPointService.orderCheckTeamCompaniesEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('Team Companies').setAction('Checking with')
    },
    validateSpots: {
      method: 'POST',
      url: ResourceEndPointService.validateSpotsEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('spots').setAction('Validating')
    },
    getOrderItems: {
      method: 'GET',
      url: ResourceEndPointService.orderItemEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order items').setAction('Retrieving')
    },
    importOrder: {
      method: 'POST',
      url: ResourceEndPointService.importGroupEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order').setAction('Recalling')
    },
    exportOrder: {
      method: 'POST',
      url: ResourceEndPointService.exportOrderEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      responseType: 'arraybuffer',
      headers: {
        Accept: '*/*'
      },
      interceptor: new NotificationInterceptor().setObject('order').setAction('Exporting'),
      transformResponse: function transformResponse(data) {
        var asset;

        if (data) {
          asset = new Blob([data], {
            type: 'application/vnd.ms-excel'
          });
        }

        return {
          data: asset
        };
      }
    },
    getOrderCounts: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/:id/counts',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order counts').setAction('Retrieving')
    },
    updateFlightDates: {
      method: 'POST',
      url: ResourceEndPointService.orderEndPoint + '/:id/flightDates',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('order flight dates').setAction('Updating')
    },
    getOrderGroups: {
      method: 'GET',
      url: ResourceEndPointService.orderEndPoint + '/:id/allOrderGroups',
      params: {
        id: '@id'
      },
      isArray: false,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('order group').setAction('Retrieving')
    },
    getOrderMetadata: {
      method: 'GET',
      url: ResourceEndPointService.orderMetadataEndpoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('orders').setAction('Retrieving'),
      timeout: $q.defer().promise
    },
    getOrderStatuses: {
      method: 'GET',
      url: ResourceEndPointService.orderStatusEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('orders').setAction('Retrieving')
    },
    validateDigitalDeliveries: {
      method: 'GET',
      url: ResourceEndPointService.digitalValidationEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('digital deliveries').setAction('Validating')
    },
    getAvailableCadHosts: {
      method: 'GET',
      url: ResourceEndPointService.availableCadHostsEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('CAD hosts').setAction('Retrieving')
    },
    cancel: {
      method: 'POST',
      url: ResourceEndPointService.cancelOrderEndPoint,
      params: {
        action: 'cancel'
      },
      isArray: false
    },
    cancelTap: {
      method: 'POST',
      url: ResourceEndPointService.cancelTapJobOrderEndPoint,
      isArray: false
    }
  });
}]);