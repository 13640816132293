/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EndPointService } from "../endpoint-service";

declare var angular: angular.IAngularStatic;

interface Params {
    id?: any,
    name?: string,
}

@Injectable()
export class BrandResource {
    private apiRoot:string;

    constructor(
        private http: HttpClient,
        private endpointService: EndPointService
    ) {
        this.apiRoot = this.endpointService.brandsEndPoint;
    }

    get(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}`)
    }

    getAll(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: new HttpParams({fromObject: params})
        })
    }

    create(params: Params, body:any):Observable<any> {
        return this.http.post(`${this.apiRoot}`, body)
    }

    save(params: Params, body: any):Observable<any> {
        return this.http.post(`${this.apiRoot}/${params.id}`, body)
    }

    getAllForReceiver(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.receiverBrandsEndPoint}`, {
            params: new HttpParams({fromObject: params})
        })
    }

    delete(params: Params):Observable<any> {
        return this.http.delete(`${this.apiRoot}/${params.id}`)
    }
}

angular.module('comcast.common.communication').factory('BrandsResourceFactory', downgradeInjectable(BrandResource));
