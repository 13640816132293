/**
 * Created by jlamy on 2/8/2018
 */
angular.module('comcast.common.communication').factory('DestinationListResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.destinationListEndPoint + '/:id', {
    id: '@id'
  }, {
    save: {
      method: 'POST',
      url: ResourceEndPointService.destinationListEndPoint,
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Saving')
    },
    getAll: {
      method: 'GET',
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Retrieving'),
      cancellable: true
    },
    getAllWithSystem: {
      method: 'GET',
      url: ResourceEndPointService.destinationListWithSystemEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Retrieving'),
      cancellable: true
    },
    create: {
      method: 'POST',
      url: ResourceEndPointService.destinationListEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Creating')
    },
    "delete": {
      method: 'DELETE',
      params: {
        id: '@id'
      },
      url: ResourceEndPointService.destinationListEndPoint + '/:id',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('deleting')
    },
    addDestination: {
      method: 'POST',
      url: ResourceEndPointService.destinationListEndPoint + '/destination',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Adding')
    },
    removeDestinationFromList: {
      method: 'DELETE',
      url: ResourceEndPointService.destinationListEndPoint + '/destination',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('Removing')
    }
  });
}]);