/**
 * Created by srober225 on 2/20/17.
 */
angular.module('comcast.common.communication').factory('DestinationResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.destinationEndPoint + '/:id', {
    id: '@id'
  }, {
    save: {
      method: 'POST',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination').setAction('Saving')
    },
    create: {
      method: 'POST',
      url: ResourceEndPointService.destinationEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination').setAction('Creating')
    },
    "delete": {
      method: 'DELETE',
      params: {
        id: '@id'
      },
      url: ResourceEndPointService.destinationEndPoint + '/:id',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination lists').setAction('deleting')
    },
    getAll: {
      method: 'GET',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('destination').setAction('Retrieving'),
      cancellable: true
    },
    getAllIds: {
      method: 'GET',
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('destination ids').setAction('Retrieving'),
      cancellable: true
    },
    getStatus: {
      method: 'GET',
      isArray: false,
      url: ResourceEndPointService.destinationEndPoint + '/:id/xferStatus',
      params: {
        id: '@id',
        destinationType: '@destinationType',
        receiverId: '@receiverId'
      },
      interceptor: new NotificationInterceptor().setObject('destination status').setAction('Retrieving'),
      cancellable: true,
      ignoreLoadingBar: true
    },
    getCapabilities: {
      method: 'GET',
      isArray: false,
      url: ResourceEndPointService.destinationEndPoint + '/:id/capabilities',
      params: {
        id: '@id'
      },
      interceptor: new NotificationInterceptor().setObject('destination icons').setAction('Retrieving'),
      cancellable: true,
      ignoreLoadingBar: true
    },
    getOperationsDestination: {
      method: 'GET',
      isArray: false,
      url: ResourceEndPointService.destinationEndPoint + '/:id/operations',
      params: {
        id: '@id'
      },
      interceptor: new NotificationInterceptor().setObject('destination icons').setAction('Retrieving'),
      cancellable: true,
      ignoreLoadingBar: true
    },
    getAllGranted: {
      method: 'GET',
      isArray: true,
      url: ResourceEndPointService.grantedDestinationsEndPoint,
      interceptor: new NotificationInterceptor().setObject('granted destinations').setAction('Retrieving'),
      cancellable: true
    },
    getOrderGroupDestinations: {
      method: 'GET',
      isArray: true,
      url: ResourceEndPointService.orderGroupDestinationsEndPoint,
      interceptor: new NotificationInterceptor().setObject('destinations').setAction('Retrieving'),
      cancellable: true
    },
    exportDestinations: {
      method: 'POST',
      url: ResourceEndPointService.destinationEndPoint + '/export/',
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('destinations').setAction('exporting')
    }
  });
}]);