import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EndPointService } from "../endpoint-service";
import { catchError, map } from 'rxjs/operators';
import { IDeleteAgencyReq } from '../../../common/services/agency.service';

declare var angular: angular.IAngularStatic;

interface Params {
    id?: any,
    name?: string,
}

@Injectable()
export class AgencyResource {
    private apiRoot:string;

    constructor(
        private http: HttpClient,
        private endpointService: EndPointService
    ) {
        this.apiRoot = this.endpointService.agenciesEndPoint;
    }

    get(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}`)
    }

    getAll(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: new HttpParams({fromObject: params})
        })
    }

    save(params: Params, body:any):Observable<any> {
        return this.http.post(`${this.apiRoot}/${params.id}`, body)
    }

    create(params: Params, body:any):Observable<any> {
        return this.http.post(`${this.apiRoot}`, body)
    }

    checkDeleteAgency(id: any): Observable<any> {
        return this.http.get(`${this.apiRoot}/checkDelete/${id}`)
    }
    deleteAgency(deleteAgencyReq: IDeleteAgencyReq): Observable<any> {
        const url = this.apiRoot + '/delete'
        return this.http.post(url, deleteAgencyReq).pipe(
            map((results:any) => { return results }),
            catchError((err) => {
              return throwError(err);
            })
          );
    }
}

angular.module('comcast.common.communication').factory('AgencyResourceFactory', downgradeInjectable(AgencyResource));