angular.module('comcast.common.communication').factory('SecurityRolePermContextResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.securityRolePermContextEndPoint, null, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.securityRolePermContextEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('Security Role Permissions').setAction('Retrieving')
    }
  });
}]);