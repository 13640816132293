/**
 * Created by seaster on 2/19/15.
 */
angular.module('comcast.common.communication').factory('MediaFormatResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.mediaFormatEndPoint + '/:id', {
    id: '@id'
  }, {
    save: {
      method: 'POST',
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('receiver profile').setAction('Saving')
    },
    query: {
      method: 'GET',
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('receiver profiles').setAction('Retrieving')
    },
    getAll: {
      method: 'GET',
      isArray: true,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('receiver profiles').setAction('Retrieving')
    }
  });
}]);