/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
angular.module('comcast.common.communication').factory('TapeVendorResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.tapeVendorEndPoint + '/:id', {
    id: '@id'
  }, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.tapeVendorEndPoint,
      isArray: true,
      interceptor: new NotificationInterceptor().setObject('tape vendor').setAction('Retrieving')
    }
  });
}]);