declare var angular: angular.IAngularStatic;

export class MigrationManager {
    static get $inject() {
        return [
            '$state'
        ];
    }
    private $state: any;

    constructor($state:any) {
        Object.assign(this, { 
            $state
        });
    }
    $onInit() {
    }
    goToDestinationDetail (id:string) {
        this.$state.go('destinationDetail', { id }).then(function () {});
    }
    goToAgency () {
        this.$state.go('agencies');
    }
}

angular.module('comcast.common.communication').service('migrationManager', MigrationManager);