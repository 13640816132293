import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EndPointService } from "../endpoint-service";

declare var angular: angular.IAngularStatic;

// Don't need this at the moment
// interface Params {
//     id?: number,
//     name?: string,
//     poNumber?: any
// }

@Injectable()
export class InvoiceResource {
    private apiRoot: string;

    constructor(
        private http: HttpClient, private endpointService: EndPointService 
    ) {
        this.apiRoot = this.endpointService.invoiceEndPoint;
    }

    getAll(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: new HttpParams({fromObject: params})
        });
    }

}

angular.module('comcast.common.communication').factory('InvoiceResourceFactory', downgradeInjectable(InvoiceResource));
