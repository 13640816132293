/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2022 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EndPointService } from "../endpoint-service";
import { catchError } from 'rxjs/operators';

declare var angular: angular.IAngularStatic;

interface Params {
    id?: any;
    isci?: string;
    loadQuiet?: Boolean;
    assetContentId?: string;
}

@Injectable()
export class SpotResource {
    private apiRoot:string;

    constructor(
        private http: HttpClient,
        private endpointService: EndPointService
    ) {
        this.apiRoot = this.endpointService.spotEndPoint;
    }

    get(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}`, {
            params: new HttpParams({fromObject: { 'ignoreLoadingBar': (params.loadQuiet)? 'true' : 'false' }})
        });
    }

    getAll(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    save(params: any, body:any):Promise<any> {
        return this.http.post(`${this.apiRoot}/${params.id}`, body, {
            params: new HttpParams({fromObject: params})
        }).toPromise();
    }

    create(params: any, body:any):Promise<any> {
        return this.http.post(`${this.apiRoot}`, body, {
            params: new HttpParams({fromObject: params})
        }).toPromise();
    }

    make(params: any, body:any):Observable<any> {
        return this.http.post(`${this.apiRoot}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    submit(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotSubmitEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    generateProxy(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.generateProxyEndpoint.replace(':id', params.id)}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    getProviderSpots(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.providerSpotEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        });
    }
    getProviderSpotsWithError(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.providerSpotEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        }).pipe(
            catchError((error) => {
                return of(error);
            })
        );
    }
    getSpotQuiet(params: Params):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}`, {
            params: new HttpParams({fromObject: { 'ignoreLoadingBar': 'true' }})
        });
    }

    getSpotMetadata(params: any):Observable<any> {
        // Don't build query parameters that have invalid values
        for (const property in params) {
            if (params[property] === undefined || params[property] === '' || params[property] === null) {
                delete params[property];
            }
        }
        return this.http.get(`${this.endpointService.spotMetadataEndPoint}`, {
            params: new HttpParams({fromObject: params}),
            observe: 'response'
        });
    }

    getAssetStats(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotAssetStatsEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    unlock(params: any):Observable<any> {
        return this.http.get(`${this.apiRoot}/${params.id}/unlock`, {
            params: new HttpParams({fromObject: params})
        });
    }

    recall(params: Params, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotRecallEndPoint.replace(':id', params.id)}`, body);
    }

    verify(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotVerifyEndPoint}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    assign(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotAssignEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    setFriends(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotSetFriendsEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    getAdIDMetadata(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotGetAdIDEndPoint}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    saveAdIDMetadata(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotSaveAdIDEndPoint}`, body, {
            params: new HttpParams()
            .set('meta',JSON.stringify(params.meta))
            .set('spotId',params.spotId)
        });
    }

    cancelIngest(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotCancelIngestEndPoint.replace(':id', params.id)}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    reprocess(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotReprocessEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    relink(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotRelinkEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    changeDownconvertPreference(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotChangeDownconvertEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    reslate(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotReslateEndPoint}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    sendToProdSvcs(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotProdSvcsEndPoint.replace(':id', params.id)}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    sendForCC(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotCCEndPoint.replace(':id', params.id)}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    getAssetMap(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotAssetMapEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    retrieveDeliveredAsset(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotDeliveredAssetEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    delete(params: Params):Observable<any> {
        return this.http.delete(`${this.apiRoot}/${params.id}`)
    }

    applyQc(params: any, body:any):Observable<any> {
        return this.http.post(`${this.endpointService.spotApplyQcEndPoint.replace(':id', params.id)}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    getSpotStatuses(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.spotStatusEndPoint}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    ingestUploadedMediaWithTap(params: any, body:any):Observable<any> {
        return this.http.put(`${this.endpointService.tapIngestEndPoint.replace(':id', params.id)}/${params.assetContentId}`, body, {
            params: new HttpParams({fromObject: params})
        });
    }

    checkIsci(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.checkIsciEndPoint}/${encodeURIComponent(params.isci)}`, {
            params: new HttpParams({fromObject: params})
        });
    }

    checkReslate(params: any):Observable<any> {
        return this.http.get(`${this.endpointService.checkSpotReslateEndPoint.replace(':id', params.id)}`, {
            params: new HttpParams({fromObject: params})
        });
    }
}

angular.module('comcast.common.communication').factory('SpotResourceFactory', downgradeInjectable(SpotResource));
