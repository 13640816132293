/**
 * Created by srober225 on 2/20/17.
 */
angular.module('comcast.common.communication').factory('VideoFormatProfileResource', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.videoFormatProfileEndPoint + '/:id', {
    id: '@id'
  }, {
    create: {
      method: 'POST',
      url: ResourceEndPointService.videoFormatProfileEndPoint,
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('videoFormatProfile').setAction('Creating')
    }
  });
}]);