angular.module('comcast.common.communication').factory('XferClientResourceFactory', ['$resource', 'EndPointService', 'NotificationInterceptor', function ($resource, ResourceEndPointService, NotificationInterceptor) {
  'use strict';

  return $resource(ResourceEndPointService.xferClientEndPoint + '/:id', {
    id: '@id'
  }, {
    getAll: {
      method: 'GET',
      url: ResourceEndPointService.xferClientEndPoint,
      isArray: false,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('xfer clients').setAction('Retrieving')
    },
    save: {
      method: 'POST',
      url: ResourceEndPointService.xferClientEndPoint + '/:id',
      params: {
        id: '@id'
      },
      isArray: false,
      interceptor: new NotificationInterceptor().setObject('xfer client').setAction('Saving')
    },
    getDownloadLink: {
      method: 'GET',
      url: ResourceEndPointService.xferClientEndPoint + '/link',
      params: {
        os: '@os',
        type: '@type'
      },
      isArray: false,
      ignoreLoadingBar: true,
      interceptor: new NotificationInterceptor().setObject('xfer client').setAction('Retrieving link')
    }
  });
}]);